import React from "react";

const Footer = () => {
  return (
    <div className="pt-6 px-4 pb-2 text-center">
      <div>
        <p>Copyright © 2023. All rights are reserved</p>
      </div>
    </div>
  );
};

export default Footer;
